import React from "react"

export default function ConsultTitle ({ title }) {
    return (
        <div>
            <h2 style={{ margin: "0 auto", width: "fit-content", fontSize: '3rem', color: '#595757', fontWeight: 'lighter', lineHeight: '6rem' }}>{title}</h2>
            <img alt={'分割线'} style={{
                width: '80%', display: 'block', margin: '0 auto'
            }} src={'/product/bim-consult-divi-line.png'} ></img>
        </div>
    )
}