import React, { useState } from "react";

import { container, logo, navBar, navBarItem, navBarItems, selected, aTagReset } from './main-menu.module.css';
import classNames from "classnames";
import IconAngle from "./widgets/IconAngle";
import logoPng from "../images/logo-full-text-lightblue.png";
import SecondNav from "./secondNav";
import { Link } from "gatsby";



/**
 * 
 * @param {{
 * className:string,
 * navPorps:[id:number,title:string],
 * active:number,
 * setActive:function()
 * }} param0 
 * @returns 
 */

function getIndex(path) {
    switch (path) {
        case '/product/':
            return 2;
        case '/project/':
            return 3;
        case '/function/':
            return 4;
        case '/about/':
            return 5;
        default:
            return 1;
    }
}

export default function MainMenu({ className, navPorps, active, setActive, path }) {

    const [selectedIndex, select] = useState(getIndex(path));

    return (
        <div className={classNames(className)}>
            <div className={container}>
                <img className={logo} src={logoPng} alt='.' />
                <nav className={navBar}>
                    <ul className={classNames(navBarItems, 'ul')}>
                        <li className={classNames(navBarItem, selectedIndex === 1 ? selected : '')}
                            
                        ><Link onClick={() => select(1)} to="/" className={aTagReset}>首页</Link>
                        </li>
                        <li className={classNames(navBarItem, selectedIndex === 2 ? selected : '')}
                            ><Link onClick={() => select(2)} to="/product" className={aTagReset}>服务产品</Link>&nbsp;
                            <IconAngle size={'.4em'} fill={'white'} direction='down'></IconAngle></li>
                        <li className={classNames(navBarItem, selectedIndex === 3 ? selected : '')}
                            ><Link onClick={() => select(3)} to="/project" className={aTagReset}>项目案例</Link>
                        </li>
                        <li className={classNames(navBarItem, selectedIndex === 4 ? selected : '')}
                            ><Link onClick={() => select(4)} to="/function" className={aTagReset}>功能体验</Link>
                        </li>
                        <li className={classNames(navBarItem, selectedIndex === 5 ? selected : '')}
                            ><Link onClick={() => select(5)} to="/about" className={aTagReset}>关于我们&nbsp;</Link>
                            <IconAngle size={'.4em'} fill={'white'} direction='down'></IconAngle></li>
                    </ul>
                </nav>
                <SecondNav navPorps={navPorps} active={active} setActive={setActive} />
            </div>
        </div>)
}