import React from "react";
import {
    container, contactInfo, share, shareItem, shareItemSina
    , shareItemQQ, shareItemWechat, shareItemBog, contactMeItemAddress, contactMeItemTelephone,
    contactMe, contactMeTip, contactMeItem, contactMeItemEmail, qtCodeImg,copyrightInfo
} from './contact-me.module.css';

import logo from '../images/qyj-full-text-lightblue.png';
import qtcode from '../images/contact-info/qycode.jpg';
import classNames from "classnames";
import CopyrightInfo from "./copyright-info";

/**
 * 联系我们
 */
export default function ContactMe() {
    return (<div className={container}>
        <p style={{backgroundColor: 'rgb(100,100,100)', padding: '1em 0 1em 10vw'}}>友情链接：三维可视化
            虚拟仿真 智慧城市 工程项目大数据 VR 人机料数据化 AI AR</p>
        <div className={contactInfo}>
            <img alt={'青云季建筑科技公司logo'} style={{width: '12vw'}} src={logo}/>
            <div className={share}>
                <span style={{display: 'block', margin: '.5em 0', fontSize: 'xx-small'}}>分享至:</span>
                <table style={{tableLayout: 'fixed', borderCollapse: 'collapse'}}>
                    <tbody>
                    <tr>
                        <td className={classNames(shareItem, shareItemSina)}></td>
                        <td className={classNames(shareItem, shareItemQQ)}></td>
                        <td className={classNames(shareItem, shareItemWechat)}></td>
                        <td className={classNames(shareItem, shareItemBog)}></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={contactMe}>
                <p className={contactMeTip}>联系我们</p>
                <p className={classNames(contactMeItem, contactMeItemEmail)}>邮箱：qingyunji2018@163.com</p>
                <p className={classNames(contactMeItem, contactMeItemAddress)}>地址：福建省福州市仓山区金洲南路汇创二期21栋</p>
                <p className={classNames(contactMeItem, contactMeItemTelephone)}>电话：18759166327（张先生）</p>
            </div>

            <img className={qtCodeImg} src={qtcode} alt='.'/>
            <CopyrightInfo className={copyrightInfo} />
        </div>

    </div>);
}
