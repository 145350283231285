export const consultBox = [
    { title: "BIM成熟度判别" },
    { title: "智慧企业搭建" },
    { title: "现有资源调研" },
    { title: "解决方案撰写" },
    { title: "寻求BIM亮点" },
    { title: "成果运营围护" },
];
export const consultServerEnter = [
    { title: "标准级", context: "为业主提供标准编制咨询服务，包括BIM存储、BIM建模、BIM编码、BIM交付标准等内容。", icon: "/icons/consult-standard.png" },
    { title: "方案级", context: "为业主提供建设项目全生命周期的BIM解决方案，如BIM各项应用解决方案、BIM创新性评估等。", icon: "/icons/consult-program.png" },
    { title: "制度级", context: "协助业主建立BIM中心，辅助业主进行BIM工作流程管理，内容包括BIM应用制度管理、BIM应用操作流程、BIM应用手册等内容。", icon: "/icons/consult-system.png" },
    { title: "服务级", context: "为业主提供各项BIM培训服务，如BIM理念知识培训、BIM软件建模培训、施工BIM应用培训、运维BIM应用培训等。", icon: "/icons/consult-service.png" },
    { title: "宣传级", context: "协助业主进行BIM宣传工作，包括宣传片的专业制作、专业论文的撰写、组织召开BIM会议等。", icon: "/icons/consult-publish.png" },
];
export const consultServerProject = [
    { title: 'BIM应用实施规划', context: "辅助业主在前期对BIM应用路线进行规划设计，提供前期BIM整体实施规划方案。", icon: "/icons/consult-project-appliction.png" },
    { title: 'BlM应用实施指导', context: "针对各项BIM应用点，为业主方相关人员提供实施指导服务。", icon: "/icons/consult-project-guide.png" },
    { title: 'BIM平台选型开发', context: "通过调研、宣贯等方式调研业主方BIM平台应用需求，定制功能提供开发服务。", icon: "/icons/consult-project-develop.png" },
    { title: 'BIM应用实施监督', context: "制定实施监督方案，协助业主方监督BIM各项应用实施开展进程。", icon: "/icons/consult-project-supervise.png" },
    { title: 'BlM应用咨询意见', context: "针对全过程BIM不同应用点提供咨询方案及咨询意见。", icon: "/icons/consult-project-opinin.png" },
    { title: 'BIM平台知识培训', context: "辅针对业主方培训需求，提供BIM 相关模块的培训服务。", icon: "/icons/consult-project-traning.png" },
];
export const manageBoxList = [
    { title: '管理知识固化', context: '固化全咨管理知识和具体工作内容，实现全咨工作指引，快速上手全咨工作；固化企业管理模板，建立企业的信息化管理知识标准', },
    { title: '服务内容配置化', context: '根据企业实力和项目需求，提供可配置的全咨服务内容，适应各种类型的项目应用', },
    { title: '管理看板定制化', context: '提供定制化的数据看板，集中业主、总咨询师、项目负责人等各方的关注点，提高管理决策效率', },
    { title: '协同管理流程化', context: '自主工作流引擎，固化流程和自定义流程相结合，关联项目各参与方，规范化及流程化各方的信息协同', },
];
export const PlatformClass = [
    { title: 'BIM应用协同教学云平台', context: '适用于工程类学校实现现代信息化教学，致力于提升整体教学质量与教学效率。 有利于促进企业生产过程中对于人才的培育，并实现所学专业与企业需求的相互对接，有效提高了人才培养质量。', icon: '/icons/manage-teach.png' },
    { title: 'BIM管控平台（咨询版）', context: '适用于工程咨询服务的信息管控平台，针对工程咨询单位根据不同咨询服务内容实现信息化管理。', icon: '/icons/manage-control.png' },
    { title: 'BIM管控平台（移动终端版）', context: '适用于建设工程管理中移动端（IOS）的运用，满足IOS客户端的使用需求，实现平台网页端、移动端数据交互。。', icon: '/icons/manage-phone.png' },
    { title: 'BIM管控平台（施工版）', context: '适用于建设工程建设过程中施工阶段的运用，平台针对施工方管理所需内容集成化信息平台管理。', icon: '/icons/manage-pc.png' },
]
export const manageConsultSerivce = [
    {
        title: '不同的服务内容',
        subItem: [
            { title: '投资决策咨询服务', context: '规划研究  项目评估  投资咨询  可行性研究', icon: "/icons/manage-consult-image.png" },
            { title: '工程建设咨询服务', context: '项目管理  招标代理  勘察  设计  监理  造价', icon: "/icons/manage-consult-hat.png" },
            { title: '设施维护咨询服务', context: '运维', icon: "/icons/manage-consult-conf.png" },
        ]
    },
    {
        title: '各阶段的应用主体服务',
        subItem: [
            { title: '决策阶段', context: '政府  业主  总控  设计  咨询', icon: "/icons/manage-consult-light.png" },
            { title: '设计阶段', context: '政府 业主 总控 设计 咨询', icon: "/icons/manage-consult-pen.png" },
            { title: '招标阶段', context: '政府  业主  总控  招标  设计  咨询  监理 施工方 供应商', icon: "/icons/manage-consult-around.png" },
            { title: '施工阶段', context: '政府 业主 总控 设计 咨询 监理 施工 供应', icon: "/icons/manage-consult-rail.png" },
            { title: '运维阶段', context: '政府 业主 运维', icon: "/icons/manage-consult-point.png" },
        ]
    },
];
export const softChara = [
    { title: "工作指引", context: '以全咨业务内容为导向，内置默 认的全咨管理的组织结构、工作流程 、工作内容，实现全咨工作指引和实施指导、技术人员快速上手' },
    { title: "移动端应用", context: '各级领导用手机APP便捷了解项目各项工作进展情况，可实现咨询业务与项 目进展的项目级和企业级的实时监控。驻场人员用手机APP可快速采集施工 现场情况，实施现场监控完成咨询同步及信息集成' },
    { title: "自定义+定制化数据分析", context: '可按项目和客户需求，可自定义配置或定制的项目数据分析看板，迅速了解项目管理与进展情况' },
    { title: "信息化数据集成", context: '与BIM模型既可深度链接协同资源，亦 可 实 现 无 B I M 模 型下的信息集成。' },
    { title: "数字资产", context: '为公司积累全咨管控经验、资料，形成完整电子档案，数字资产永不丢失，一目了然，通过工作内容设置及固化表单，形成项目模板，在其他类似项目中使用提供咨询方案及咨询意见。' },
    { title: "主流模型格式任意导入", context: '可导入多种主流模型格式文件，如Revit等，无模型交互障碍' },
    { title: "协同应用", context: '协同圈即时沟通，高效协同，多种沟通类型：支持文字、文件、图片、语音视频等，互动方式，处处留痕;各阶段交流信息留痕记录存储，基于统一端口沟通，形成可追溯的信息化过程资料。' },
    { title: "动态投资管控", context: '以合同管理为基础，结合项目进度的全流程项目资金动态管控' },
    { title: "模块化配置", context: '灵活的模块化设置，适应各种项目管控组合 ：1+N组合，全过程造价、监理、投资策划等，可灵活配置管控方案' },
]