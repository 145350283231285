/**
 * 7.5:
 * 二次导航栏需要以组件的形式在framework中添加，现在先暂时不考虑二次导航栏的制作
 * 完成静态页面的修订和组件的绘制.
 * 7.6:
 * 次级导航栏组件提前完成，可以在以后考虑添加slot
 * css和结构同时写太耗费时间，先把结构和数据都整合起来，之后在考虑css的事情
 * 在后面编写css时，记得把footer的备案号等写上
 */
// import classNames from "classnames";
import React, { useState } from "react";
//#region component
import MainFrame from "../components/main-frame";//框架
import MainMenu from "../components/main-menu";//导航栏
// import Carousel from "../components/carousel";//轮播图
import ConsultTitile from "../components/widgets/consultTitle"//标头
import ProductBrief from "../components/widgets/product-brief";//在每一个次级路由中的简介开头
//#endregion

//#region cssRely
import {
    pageHeader,
    mainMenu,
} from "./index.module.css"

import {
    main,
    // mainContainer,
    consultContainer,
    moduleContainer,
    moduleSub,
    spotContainer,
    enterContainer,
    projectContainer,
    enterBox,
    projectBox,
    projectTrunk,

    manageBox,
    platBox,
    manageConslutContainer,
    manageConsultTitle,
    manageConsultBox,
    // softCharaContainer,
    softCharaBox,
    optionContainer //所有需要向内缩进的
} from "./product.module.css"
//#endregion


//#region option
import { productsNav } from "../staticConfMange/navMange.js"
import {
    consultBox,
    consultServerEnter,
    consultServerProject,

    manageBoxList,
    PlatformClass,
    manageConsultSerivce,
    softChara,

} from "../staticConfMange/productMessage";

//#endregion

export default function Product({ path }) {
    /**
     * @param {0|1|2|3} active 控制显示次级页面，可选值：  0：BIM全过程咨询,1:工程建设管理平台，2：工程项目大数据建设
     */
    const [active, setActive] = useState(0);
    return (
        <MainFrame>
            <header className={pageHeader}>
                <MainMenu className={mainMenu} navPorps={productsNav} active={active} setActive={setActive} path={path} />
                {/* <Carousel /> */}
                <img alt={productsNav[active].title} src="/product/product-bg.jpg" width="100%" />
            </header>
            {/* 此处出现的多个div外壳结构一样的情况，后期在时间允许、技术成熟的情况下，请把这三个.main拆分成3个子组件之后用map的形式遍历出来，便于维护和观感。 */}
            <div className={main} style={{ display: active === 0 ? "block" : 'none' }}>
                <ProductBrief
                    {...{
                        title: 'BIM全过程咨询',
                        subtitle: '致力于工程项目设计可视化、数字集成化的',
                        content: '通过三维数字技术，将建筑工程项目各相关信息集成在一起，并对项目事前的各项问题进行预警和分析，使工程项目各参与方能够了解和应对，同时为协同工作提供坚实的基础，为项目的全过程提供整体解决方案与服务。',
                        img: {
                            path: "/product/bim-consult.png",
                            width: '60%'
                        }
                    }}
                ></ProductBrief>
                <div className={consultContainer}>
                    <ConsultTitile title={'咨询理念'} />
                    <p style={{
                        lineHeight: '2.6rem',
                        textAlign: 'center',
                        marginTop: "10px"
                    }}>
                        我们坚持站在客户的角度思考问题，用领先的互动策略思考模式打破常规。
                        <br />
                        BIM咨询服务旨在面向多领域客户提供全方位全过程咨询服务，真正推动企业数字化转型和项目BIM应用落地。
                    </p>
                    <div className={moduleContainer}>
                        {
                            consultBox.map(item => (
                                <div key={item.title} className={moduleSub}>{item.title}</div>
                            ))
                        }
                    </div>
                </div>
                <div className={spotContainer}>
                    <ConsultTitile title={'咨询亮点'}></ConsultTitile>
                    <img alt="咨询亮点" style={{
                        width: '60%', display: 'block', margin: '20px auto'
                    }} src={'/product/stop-point.png'}></img>
                </div>
                <div className={enterContainer}>
                    <ConsultTitile title={(<p>我们提供的服务-<span style={{ color: '#93ddf7' }}>企业级</span></p>)}></ConsultTitile>
                    <div className={enterBox}>
                        {
                            consultServerEnter.map(item => (
                                <div key={item.title}>
                                    <img alt={item.title} src={item.icon}></img>
                                    <h3>{item.title}</h3>
                                    <p>{item.context}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={projectContainer}>
                    <ConsultTitile title={(<p>我们提供的服务-<span style={{ color: '#93ddf7' }}>项目级</span></p>)}></ConsultTitile>
                    <div className={projectBox}>
                        {
                            consultServerProject.map(item => (
                                <div key={item.title} className={projectTrunk}>
                                    <img alt={item.title} src={item.icon}></img>
                                    <div>
                                        <h3>{item.title}</h3>
                                        <p>{item.context}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className={main} style={{ display: active === 1 ? "block" : 'none' }}>
                <ProductBrief
                    {...{
                        title: "工程项目管理平台",
                        subtitle: "大数据建设环境的--",
                        content: "青云季建立云平台+各方参与+BIM工程项目全过程总控管理机制，从根本上解决项目全生命周期各阶段和各专业系统间信息断层问题，全面提高从策划、设计、招采、施工、运维的信息化服务水平和应用效果。",
                        img: {
                            path: "/product/manage-consult.png",
                            width: "50%"
                        },
                        slot: (
                            <div className={manageBox}>
                                {
                                    manageBoxList.map(item => (
                                        <div key={item.title}>
                                            <div className="title">
                                                {item.title}
                                            </div>
                                            <p>{item.context}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }}
                />
                <div className={optionContainer}>
                    <ConsultTitile title={'产品简介'}></ConsultTitile>
                    <h3 style={{ fontSize: '2.6rem', textAlign: 'center', margin: "20px 0 40px 0" }}>BIM管理平台为工程项目全生命周期业务服务的管理平台。</h3>
                    <img alt="管理平台项目" src="/product/reval-bg-1.png" style={{
                        display: 'block',
                        width: '100%',
                    }}></img>
                </div>
                <div className={optionContainer}>
                    <ConsultTitile title={"平台分类"}></ConsultTitile>
                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                        {
                            PlatformClass.map(item => (
                                <div key={item.title} className={platBox}>
                                    <img alt={item.title} src={item.icon} />
                                    <div className="title">{item.title}</div>
                                    <p>{item.context}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={optionContainer}>
                    <ConsultTitile title={"平台定位"} />
                    <h3 style={{ textAlign: "center", fontSize: '1rem', margin: '16px 0' }}>服务于BIM全过程咨询</h3>
                    <p style={{ textAlign: 'center', width: '1170px', margin: '0 auto' }}>数字化管理的实施应满足承担项目全过程全部专业咨询服务，全面整合项目建设过程中所需的投资咨询、勘察、设计、造价咨询、招标代理、监理、运营维护咨询以及全过程工程项目管理等咨询服务业务，并可通过<b>1+N+X”</b>自由组合。</p>
                </div>
                <div>
                    <img alt="平台定位" src="/product/manage-control-bg.png" style={{ width: '100%' }}></img>
                </div>
                <div className={optionContainer}>
                    <div>
                        <h3 style={{ textAlign: "center" }}>信息化集成-BIM的咨询服务</h3>
                        <p style={{ textAlign: "center", margin: '16px 0 36px 0' }}>在建设项目各阶段，让各参与方进行工程项目BIM信息化集成应用</p>
                        <div className={manageConslutContainer}>
                            {
                                manageConsultSerivce.map(item => (<div key={item.title}>
                                    <div className={manageConsultTitle}>{item.title}</div>
                                    <div>
                                        {
                                            item.subItem.map(sub => (<div key={sub.title} className={manageConsultBox}>
                                                <img alt={sub.title} src={sub.icon}></img>
                                                <div >
                                                    <div>{sub.title}</div>
                                                    <p>{sub.context}</p>
                                                </div>
                                            </div>))
                                        }
                                    </div>
                                </div>))
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <ConsultTitile title={"核心价值"} />
                    <img alt="核心价值" src="/product/manage-core-value.png" style={{ width: '50%', display: 'block', margin: '0 auto' }}></img>
                </div>
                <div className={optionContainer}>
                    <ConsultTitile title={"软件特点"} />
                    <div className={softCharaBox}>
                        {
                            softChara.map(item => (<div key={item.title}>
                                <div className="title">{item.title}</div>
                                <p>{item.context}</p>
                            </div>))
                        }
                    </div>
                </div>
            </div>
            <div className={main} style={{ display: active === 2 ? "block" : "none" }}>
                <ProductBrief
                    {...{
                        title: '工程项目大数据建设',
                        subtitle: '致力于智慧城市建设愿景的--',
                        content: '将大量数据进行可视化设计、交互设计展示，实现各类云数据和专业数据的实时图形可视化、场景化,清晰有效地传达与沟通信息，在美观与功能之间达到平衡，帮助人们深入理解洞察复杂的数据集。',
                        img: {
                            path: "/product/project-brief-bg.png",
                            width: '60%'
                        },
                        slot: (<p>通过三维数字技术，将建筑工程项目各相关信息集成在一起，并对项目事前的各项问题进行预警和分析，使工程项目各参与方能够了解和应对，同时为协同工作提供坚实的基础，为项目的全过程提供整体解决方案与服务。</p>)
                    }}
                />
                <div>
                    <img alt="banner" src="/product/project-banner-1.jpg" style={{ width: '100%' }}></img>
                </div>
                <div className={optionContainer}>
                    <img alt="arrow" src="/product/project-arrow.png" style={{
                        display: 'block',
                        width: '20%',
                        margin: '0 auto'
                    }}></img>
                </div>
                <div>
                    <img alt="banner" src="/product/project-banner-2.jpg" style={{ width: '100%' }}></img>
                </div>
            </div>
        </MainFrame>
    )
}