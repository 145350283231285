import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";


//#region cssRely
import {
    secondContainer,
    hidden,
    navItem,
    isActive
} from "./secondNav.module.css";
//#endregion
/**
 * 此组件为次级导航栏组件，作用是点击对应的Tab显示对应的页面
 * 对应页面的索引设置在../pages/staticConfMange/navMange.js
 * 目前这个次级导航栏完成进度接近完全，非需求性的情况下是不需要修改的，当你发现代码有显示的问题时，请检查自身的代码
 * @param {{
 * navPorps:[],
 * active:number,
 * setActive?:function
 * }} param0 
 * @returns {JSX.Element}
 */
export default function SecondNav({ navPorps = [], active = 0, setActive }) {
    console.log(navPorps, 666);
    console.log(navPorps.href);
    return (
        <div className={navPorps.length > 0 ? secondContainer : hidden}>
            {

                navPorps.map(item => (
                    <div key={item.id}
                        onClick={() => setActive(item.id)}
                        className={classNames(
                            navItem,
                            item.id === active ? isActive : ''
                        )}> <Link to={item.href} style={{textDecoration:'none'}} > {item.title} </Link>        </div>
                ))
            }
        </div>
    )
}