// extracted by mini-css-extract-plugin
export var businessDescribe = "index-module--businessDescribe--NsAWT";
export var businessDescribeContent = "index-module--businessDescribeContent--5snhm";
export var businessDescribeHeader = "index-module--businessDescribeHeader--rqhR3";
export var businessDescribeVerticalLine = "index-module--businessDescribeVerticalLine--UaUfr";
export var businessDescribeWrap = "index-module--businessDescribeWrap--j2erL";
export var contentText = "index-module--content-text--hTS6K";
export var contentTitle = "index-module--content-title--WD6ys";
export var flowNode = "index-module--flow-node--JIVBA";
export var flowNode__icon = "index-module--flow-node__icon---23Lj";
export var flowNode__text = "index-module--flow-node__text--h6JB7";
export var flowNodes = "index-module--flow-nodes--i5TCj";
export var horizontalLine = "index-module--horizontal-line--OrLkx";
export var horizontalLineGray = "index-module--horizontal-line-gray--1WfDU";
export var mainContent = "index-module--main-content--W2brm";
export var mainContentSection = "index-module--main-content-section--D6UyH";
export var mainContentTitleHorizontalLine = "index-module--mainContentTitle-horizontal-line--+tghM";
export var mainMenu = "index-module--mainMenu--oKvBN";
export var pageHeader = "index-module--page-header--OIZHA";
export var subhead = "index-module--subhead--MYd5e";
export var subheadItem = "index-module--subhead-item--moVHU";
export var verticalLine = "index-module--vertical-line--Z+qMM";