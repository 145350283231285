/**
 * 一般放在每个页面底部的版权信息栏
 */
import React from "react";

import classNames from "classnames";

const sectionStyle = {};


export default function CopyrightInfo({className}) {
    return (<section className={className}>
        <p style={{
            textAlign: 'center',
            color: 'white',
            whiteSpace: 'nowrap'
        }}>
            福建青云季建筑科技有限公司&nbsp;©&nbsp;&nbsp;版权所有&nbsp;&nbsp;www.qyjbim.com&nbsp;&nbsp;All&nbsp;Rights&nbsp;Reserved&nbsp;&nbsp;&nbsp;&nbsp;工信部备案：闽ICP备2022009386号-1
        </p>
    </section>)
}
