// extracted by mini-css-extract-plugin
export var contactInfo = "contact-me-module--contactInfo--gbYGh";
export var contactMe = "contact-me-module--contactMe--NUebM";
export var contactMeItem = "contact-me-module--contactMeItem--SVBZ4";
export var contactMeItemAddress = "contact-me-module--contactMeItemAddress--EsDf3";
export var contactMeItemEmail = "contact-me-module--contactMeItemEmail--RRGFP";
export var contactMeItemTelephone = "contact-me-module--contactMeItemTelephone--ytWmA";
export var contactMeTip = "contact-me-module--contactMeTip--8eeGi";
export var container = "contact-me-module--container--rgWKu";
export var copyrightInfo = "contact-me-module--copyrightInfo--LJkd4";
export var qtCodeImg = "contact-me-module--qtCodeImg--Ldlce";
export var share = "contact-me-module--share--pN4L5";
export var shareItem = "contact-me-module--shareItem--b8e2p";
export var shareItemBog = "contact-me-module--shareItemBog--wunem";
export var shareItemQQ = "contact-me-module--shareItemQQ--X4XH7";
export var shareItemSina = "contact-me-module--shareItemSina--Dj7GZ";
export var shareItemWechat = "contact-me-module--shareItemWechat--Lxafb";