/**
 * 主页面框架
 * @param children
 * @returns {*}
 * @constructor
 */
import React from "react";

import ContactMe from "./contact-me";
import CopyrightInfo from "./copyright-info";
/**
 *
 * @param {{children:JSX.Element}} param0
 * @returns {JSX.Element}
 */
export default function MainFrame({ children }) {
    return (<div>
        <title>青云季建筑科技</title>
        <main>
            {
                children
            }
        </main>
        <footer>
            <ContactMe />
        </footer>
    </div>)
}
