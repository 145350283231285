import React from "react";
import {
    mainContainer,
} from "../../pages/product.module.css"
/**
 * 
 * @param {{
 * title:string|JSX.Element,
 * subtitle:string|JSX.Element,
 * content:string|JSX.Element,
 * img:{path:string,width:string},
 * slot?:JSX.Element
 * }} param0 
 */
export default function ProductBrief({ title, subtitle, content, img, slot }) {
    return (
        <div className={mainContainer}>
            <h5 style={{ color: '#93ddf7', fontSize: '1.6rem' }}>{subtitle}</h5>
            <h2 style={{ color: '#004097', fontSize: '3rem', lineHeight: '5rem', fontWeight: 'bolder' }}>{title}</h2>
            <p style={{ textIndent: '2em', lineHeight: '2.4rem' }}>{content}</p>
            <img alt={title} style={{
                width: img.width, display: 'block', margin: '0 auto'
            }} src={img.path} />
            {slot}
        </div>
    )
}