import React, {useEffect, useState} from "react";

const directionAngleMap = {
    'right': 0,
    'left': 180,
    'up': -90,
    'down': 90
}

export default function IconAngle({direction = 'right', fill = '', size = '1em',className}) {

    const [rotateAngle, setAngle] = useState(0);
    useEffect(() => {
        if (directionAngleMap.hasOwnProperty(direction))
            setAngle(directionAngleMap[direction]);
    }, [direction]);

    return (
        <img className={className} alt='尖角' style={{width: size, height: `calc(17 / 12 * ${size})`, transform: `rotate(${rotateAngle}deg)`}}
             src={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${fill}' xml:space='preserve' viewBox='0 0 16.45 23.97'%3E %3Cg%3E  %3Cpolygon className='fil0' points='-0,23.97 16.45,11.99 -0,0 -0,2.11 13.55,11.99 -0,21.86 '/%3E %3C/g%3E%3C/svg%3E`}
        />)
}